<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card no-body v-if="!full_screen" action-collapse>
      <b-card-header class="d-block">
        <b-card-title>
          {{ lang("t_callRecords") }}
          <b-button
            v-b-modal.modal-filter
            size="sm"
            class="px-50 py-50 float-right"
            variant="flat-secondary"
            v-b-tooltip.hover.top="lang('t_filters')"
            v-b-tooltip.hover.v-secondary
          >
            <b-icon icon="funnel"></b-icon>
          </b-button>
        </b-card-title>
        <!-- <b-card-sub-title class="mt-1">
          {{ lang("t_records") }}
        </b-card-sub-title> -->
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col cols="3" v-for="(item, i) in getSelectedFilters" :key="i">
            <!-- {{ item }} -->
            <b-form-group v-if="item.type == 'select'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values[item.internal_name]"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :label="item.label"
                :reduce="(opt) => opt[item.key]"
                :options="item.options"
              />
            </b-form-group>
            <b-form-group v-if="item.type == 'text'">
              <b-form-input
                @update="
                  () => {
                    filter_values[item.internal_name] = filter_values[
                      item.internal_name
                    ]
                      .split(' ')
                      .join('');
                  }
                "
                @input="
                  () => {
                    filter_values[item.internal_name] = filter_values[
                      item.internal_name
                    ]
                      .split(' ')
                      .join('');
                  }
                "
                :placeholder="lang(item.display_name)"
                v-model="filter_values[item.internal_name]"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group v-if="item.type == 'number'">
              <b-form-input
                type="number"
                :placeholder="lang(item.display_name)"
                v-model="filter_values[item.internal_name]"
              >
              </b-form-input>
            </b-form-group>
            <date-range-picker
              v-if="item.type == 'datetime'"
              auto-apply
              append-to-body
              class="d-block"
              v-model="filter_values[item.internal_name]"
              :ref="`picker${i}`"
              :locale-data="{
                direction: 'ltr',
                format: 'dd/mm/yyyy HH:mm',
                separator: ' - ',
                applyLabel: 'Uygula',
                cancelLabel: 'İptal',
                weekLabel: 'H',
                customRangeLabel: 'Özel Aralık',
                daysOfWeek: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
                monthNames: [
                  'Oca',
                  'Şub',
                  'Mar',
                  'Nis',
                  'May',
                  'Haz',
                  'Tem',
                  'Ağu',
                  'Eyl',
                  'Eki',
                  'Kas',
                  'Ara',
                ],
                firstDay: 1,
              }"
              :timePicker="true"
              :timePicker24Hour="true"
              :time-picker-seconds="false"
            >
              <template v-slot:input="picker">
                <span>
                  {{ setDateFormat(picker.startDate) }} -
                  {{ setDateFormat(picker.endDate) }}
                </span>
              </template>
              <template #ranges="ranges">
                <div class="ranges">
                  <b-row
                    class="mx-25"
                    v-for="(range, name) in ranges.ranges"
                    :key="name"
                  >
                    <b-col>
                      <b-button
                        size="sm"
                        block
                        @click="ranges.clickRange(range)"
                        variant="flat-primary"
                      >
                        {{ name }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </template>
              <div slot="footer" slot-scope="data" class="slot">
                <div
                  class="border-top px-1 py-1 d-flex justify-content-between align-items-center"
                >
                  <div>
                    {{ data.rangeText }}
                  </div>
                  <div class="float-right">
                    <b-button
                      size="sm"
                      @click="data.clickApply"
                      v-if="!data.in_selection"
                      variant="flat-primary"
                    >
                      {{ lang("t_apply") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </date-range-picker>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <date-range-picker
                auto-apply
                ref="picker"
                :locale-data="{
                  direction: 'ltr',
                  format: 'dd/mm/yyyy HH:mm',
                  separator: ' - ',
                  applyLabel: 'Uygula',
                  cancelLabel: 'İptal',
                  weekLabel: 'H',
                  customRangeLabel: 'Özel Aralık',
                  daysOfWeek: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
                  monthNames: [
                    'Oca',
                    'Şub',
                    'Mar',
                    'Nis',
                    'May',
                    'Haz',
                    'Tem',
                    'Ağu',
                    'Eyl',
                    'Eki',
                    'Kas',
                    'Ara',
                  ],
                  firstDay: 1,
                }"
                :timePicker="true"
                :timePicker24Hour="true"
                :time-picker-seconds="false"
              >
                <template v-slot:input="picker">
                  <span>
                    {{ setDateFormat(picker.startDate) }} -
                    {{ setDateFormat(picker.endDate) }}
                  </span>
                </template>
                <template #ranges="ranges">
                  <div class="ranges">
                    <b-row
                      class="mx-25"
                      v-for="(range, name) in ranges.ranges"
                      :key="name"
                    >
                      <b-col>
                        <b-button
                          size="sm"
                          block
                          @click="ranges.clickRange(range)"
                          variant="flat-primary"
                        >
                          {{ name }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <div slot="footer" slot-scope="data" class="slot">
                  <div
                    class="border-top px-1 py-1 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      {{ data.rangeText }}
                    </div>
                    <div class="float-right">
                      <b-button
                        size="sm"
                        @click="data.clickApply"
                        v-if="!data.in_selection"
                        variant="flat-primary"
                      >
                        {{ lang("t_apply") }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </date-range-picker>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-row>
          <b-col :key="i" v-for="(item, i) in standart_filters">
            <b-form-group v-if="item.internal_name == 'Project'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedProjects"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="projects"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Queue'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedQueues"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="queues"
              />
            </b-form-group>

            <b-form-group v-if="item.internal_name == 'Agent'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedAgents"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.username"
                :options="agents"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'RemoteNumber'">
              <b-form-input
                v-model="filter_values.selectedNumber"
                :placeholder="lang(item.display_name)"
                @update="
                  () => {
                    filter_values.selectedNumber = filter_values.selectedNumber
                      .split(' ')
                      .join('');
                  }
                "
                @input="
                  () => {
                    filter_values.selectedNumber = filter_values.selectedNumber
                      .split(' ')
                      .join('');
                  }
                "
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col :key="i" v-for="(item, i) in advanced_filters">
            <b-form-group v-if="item.internal_name == 'TerminateSide'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedTerminateSides"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['local', 'remote']"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Verdict'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedVerdicts"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="finish_code"
                :reduce="(item) => item.finish_code"
                :options="finish_codes"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Campaign'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedCampaigns"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="campaigns"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'InteractionID'">
              <b-form-input
                v-model="filter_values.selectedInteractionID"
                :placeholder="lang(item.display_name)"
                @update="
                  () => {
                    filter_values.selectedInteractionID =
                      filter_values.selectedInteractionID.split(' ').join('');
                  }
                "
                @input="
                  () => {
                    filter_values.selectedInteractionID =
                      filter_values.selectedInteractionID.split(' ').join('');
                  }
                "
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <b-form-input
              type="number"
              v-model="filter_values.selectedMinWaitInQueue"
              :placeholder="lang('t_minWaitInQueue')"
            />
          </b-col>
          <b-col>
            <b-form-input
              type="number"
              v-model="filter_values.selectedMaxWaitInQueue"
              :placeholder="lang('t_maxWaitInQueue')"
            />
          </b-col>
          <b-col>
            <b-form-input
              type="number"
              v-model="filter_values.selectedMinDuration"
              :placeholder="lang('t_minDuration')"
            />
          </b-col>
          <b-col>
            <b-form-input
              type="number"
              v-model="filter_values.selectedMaxDuration"
              :placeholder="lang('t_maxDuration')"
            />
          </b-col>
        </b-row> -->

        <!-- <b-row>
          <b-col>
            <b-form-group>
              <flat-pickr
                :placeholder="lang('t_rEvaluatedStartTime')"
                v-model="filter_values.selectedEvaluatedStartTime"
                class="form-control"
                :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                  allowInput: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <flat-pickr
                :placeholder="lang('t_rEvaluatedEndTime')"
                v-model="filter_values.selectedEvaluatedEndTime"
                class="form-control"
                :config="{
                  dateFormat: 'Y-m-d H:i',
                  enableTime: true,
                  allowInput: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
          </b-col>
          <b-col
            :key="i"
            v-for="(item, i) in quality_filters.filter((e) =>
              ['Evaluator', 'IsEvaluated'].includes(e.internal_name)
            )"
          >
            <b-form-group v-if="item.internal_name == 'Evaluator'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedEvaluator"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.username"
                :options="agents"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'IsEvaluated'">
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedIsEvaluated"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="YesNoOptions"
                :reduce="(item) => item.internal_name"
                label="display_name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            :key="i"
            v-for="(item, i) in quality_filters.filter((e) =>
              [
                'MinQualityPoint',
                'MaxQualityPoint',
                'QualityForm',
                'IsObjection',
              ].includes(e.internal_name)
            )"
          >
            <b-form-group v-if="item.internal_name == 'MinQualityPoint'">
              <b-form-input
                type="number"
                v-model="filter_values.selectedMinQualityPoint"
                :placeholder="lang('t_rMinQualityPoint')"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'MaxQualityPoint'">
              <b-form-input
                type="number"
                v-model="filter_values.selectedMaxQualityPoint"
                :placeholder="lang('t_rMaxQualityPoint')"
              />
            </b-form-group>

            <b-form-group>
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedQualityForm"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item._id"
                :options="quality_forms"
              />
            </b-form-group>
            <b-form-group>
              <v-select
                :closeOnSelect="false"
                v-model="filter_values.selectedIsObjection"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="YesNoOptions"
                label="display_name"
                :reduce="(item) => item.internal_name"
              />
            </b-form-group>
          </b-col>
        </b-row> -->

        <b-button
          @click="
            () => {
              getReportData(1);
            }
          "
          class="float-right"
          variant="primary"
        >
          {{ lang("t_get") }}
        </b-button>
      </b-card-body>
    </b-card>

    <b-card v-if="items.length > 0" no-body>
      <b-card-body class="px-0">
        <b-card-text class="d-flex mx-2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>

            <b-form-input
              v-model="filter"
              :placeholder="lang('t_search')"
            ></b-form-input>
          </b-input-group>

          <!-- <b-button size="sm" class="px-50 py-50 ml-2" variant="flat-secondary">
            <b-icon icon="download"></b-icon>
          </b-button>
          <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
            <b-icon icon="funnel"></b-icon>
          </b-button> -->
          <b-button size="sm" class="px-50 py-50 ml-1" variant="flat-secondary">
            <b-icon :icon="true ? 'fullscreen' : 'fullscreen-exit'"></b-icon>
          </b-button>
        </b-card-text>
        <b-row class="mx-0" style="display: grid">
          <b-table
            bordered
            style="width: 100%; overflow: auto"
            :filter="filter"
            small
            hover
            :items="items"
            :fields="fields"
            responsive
            show-empty
            :empty-text="lang('t_noRecord')"
            :busy="items.length == 0"
            striped
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :tbody-tr-class="rowClassRecords"
            @row-dblclicked="checkActivityLog"
          >
            <template #empty="scope">
              <b-container class="mt-5">
                <b-row>
                  <b-col class="text-center text-muted mt-50">
                    <span>{{ scope.emptyText }}</span>
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <!-- <template #cell(RecordingFileName)="data">
              <center>
                <b-button
                  class="py-25 px-75"
                  v-if="data.item.RecordingFile != ''"
                  size="sm"
                  @click="open_audio2(data)"
                  variant="outline-primary"
                >
                  <feather-icon
                    v-if="!data.detailsShowing"
                    icon="PlayIcon"
                  ></feather-icon>
                  <feather-icon v-else icon="XIcon"></feather-icon>
                </b-button>
              </center>
            </template> -->
            <template #cell(note)="data">
              <b-button
                variant="light-secondary"
                v-if="data.value.length > 0"
                @click="openNote(data.value)"
                class="py-50 px-50"
              >
                <i class="bi bi-sticky font-medium-1"></i>
              </b-button>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                boundary="viewport"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
                toggle-class="px-50 py-50"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  v-if="other_permission.includes('download_sound')"
                  @click="checkDownloadActivityLog(data.item)"
                >
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">{{ lang("t_export") }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="open_quality_form_modal(data)">
                  <feather-icon icon="CheckSquareIcon" />
                  <span class="align-middle ml-50">{{
                    lang("t_qualityForm")
                  }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.EvaluateEvent == true"
                  @click="OpenApplyDeleteForm(data)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{
                    lang("t_deleteQualityForm")
                  }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :disabled="!data.item.ObjectionEvent"
                  @click="open_objection_modal(data)"
                >
                  <feather-icon icon="AlertTriangleIcon" />
                  <span class="align-middle ml-50">{{
                    lang("t_answerToObject")
                  }}</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click="openAudio(row.item)">
                  <feather-icon icon="ExternalLinkIcon" />
                  <span class="align-middle ml-50">{{
                    lang("t_openInNewWindow")
                  }}</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </template>
            <template #cell(RecordingFile)="data">
              <b-dropdown
                boundary="viewport"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item v-if="data.item.RecordingFile != ''">
                  <b-icon icon="play-fill"></b-icon>
                  <span class="align-middle ml-50">{{
                    lang("t_recordingFile")
                  }}</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="data.item.note.length > 0">
                  <b-icon icon="card-text"></b-icon>
                  <span class="align-middle ml-50">{{ lang("t_note") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(Queue)="data">
              <b-badge variant="light-primary">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </b-badge>
            </template>

            <template #cell(Direction)="data">
              <div class="d-flex align-items-center">
                <b-icon
                  icon="telephone-inbound"
                  v-if="data.value == 'inbound'"
                ></b-icon>
                <b-icon
                  icon="telephone-outbound"
                  v-if="data.value == 'outbound'"
                ></b-icon>
                <b-icon
                  icon="telephone-outbound"
                  v-if="data.value == 'dialer'"
                ></b-icon>
                <span class="ml-50">{{ data.value }}</span>
              </div>
            </template>
          </b-table>
        </b-row>
      </b-card-body>

      <b-card-footer class="py-75 border-0">
        <div class="d-flex justify-content-between d-flex align-items-center">
          <div class="d-flex align-items-center mb-0">
            <!-- <span class="text-nowrap"> Showing 1 to </span> -->
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="['10', '25', '50']"
              class="mr-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap text-muted font-small-3">
              {{
                lang("t_total") +
                " " +
                items.length +
                " " +
                lang("t_row").toLowerCase()
              }}
            </span>
          </div>

          <b-pagination
            @input="getReportData()"
            size="sm"
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            align="right"
            :total-rows="totalRows"
            first-number
            last-number
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="16" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="16" />
            </template>
          </b-pagination>
        </div>
      </b-card-footer>
    </b-card>
    <b-row
      :class="displaySound ? 'd-block' : 'd-none'"
      style="
        position: fixed;
        bottom: 0;
        z-index: 9999;
        left: 0;
        width: 100vw;
        margin-left: 0;
        margin-right: 0;
      "
    >
      <b-col class="px-0">
        <b-card class="mb-0">
          <b-card-text>
            <b-row class="mx-0">
              <b-col
                class="d-flex align-items-center justify-content-center px-0"
              >
                <b-dropdown
                  v-if="wavesurfer"
                  class="mr-50 ml-2"
                  :text="wavesurfer.getPlaybackRate() + 'x'"
                  variant="flat-secondary"
                >
                  <b-dropdown-item @click="setSoundSpeed(0.25)">
                    0.25x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(0.5)">
                    0.5x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(0.75)">
                    0.75x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1)">
                    1x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.25)">
                    1.25x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.5)">
                    1.50x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.75)">
                    1.75x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(2)">
                    2x
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="SkipBackIcon" />
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="btn-icon rounded-circle mx-50"
                  @click="
                    () => {
                      wavesurfer.playPause();
                    }
                  "
                >
                  <feather-icon
                    v-if="wavesurfer"
                    size="20"
                    :icon="wavesurfer.isPlaying() ? 'PauseIcon' : 'PlayIcon'"
                  />
                </b-button>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="SkipForwardIcon" />
                </b-button>
                <span style="width: 3rem" class="ml-3">
                  {{ `${formatTime(parseInt(currentTime))}` }}
                </span>
                <b-col class="cursor-pointer" :id="`waveform`"></b-col>
                <span
                  style="width: 3rem"
                  v-if="wavesurfer && wavesurfer.decodedData"
                >
                  {{
                    `${formatTime(parseInt(wavesurfer.decodedData.duration))}`
                  }}
                </span>
                <b-button
                  @click="closeSound"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle ml-5"
                >
                  <feather-icon size="20" icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>

            <!-- <div id="hover"></div> -->
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-card
      v-if="false"
      class="my-0"
      :style="{ height: full_screen ? '66vh' : '50vh' }"
    >
      <b-row align-h="between">
        <b-col
          cols="4"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ lang("t_show") }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ lang("t_entries") }}</label>
        </b-col>
        <b-col md="6" class="my-1">
          <b-button-group class="float-right w-50">
            <b-form-input v-model="filter" :placeholder="lang('t_search')" />
            <b-button
              class=""
              variant="flat-primary"
              @click="full_screen = !full_screen"
            >
              <feather-icon
                :icon="!full_screen ? 'Maximize2Icon' : 'Minimize2Icon'"
              ></feather-icon>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row style="max-width: 100%; overflow-x: auto" class="mx-0 px-0">
        <b-col cols="12" class="mx-0 px-0">
          <b-table
            :style="{ height: full_screen ? '48vh' : '32vh' }"
            small
            bordered
            striped
            hover
            responsive="sm"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :tbody-tr-class="rowClassRecords"
          >
            <template #row-details="row">
              <b-card class="bg-transparent">
                <b-row>
                  <b-col cols="3">
                    <audio
                      :id="`voyce_audio_${new Date(
                        row.item.StartTime
                      ).getTime()}`"
                      ref="player"
                      controls
                      preload="none"
                      class="w-100"
                    >
                      <source :src="selected_audio" type="audio/mpeg" />
                      <source
                        :src="selected_audio.replace('.ogg', '.mp3')"
                        type="audio/mpeg"
                      />
                      Your browser does not support the audio element.
                    </audio>
                  </b-col>
                  <b-col>
                    <b-badge
                      variant="primary"
                      class="mr-1 mt-1 badge-glow"
                      id="a_rate"
                      >{{ `${row.item.play_back_rate}x` }}
                    </b-badge>
                    <b-button-group variant="outline-primary" size="sm">
                      <b-button
                        @click="speedDown(row)"
                        v-b-tooltip.hover.top="'<< 0.25x'"
                        v-b-tooltip.hover.v-primary
                        variant="outline-primary"
                      >
                        <feather-icon icon="ChevronsLeftIcon"></feather-icon>
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'1x'"
                        v-b-tooltip.hover.v-primary
                        @click="speedNormal(row)"
                        variant="outline-primary"
                      >
                        <feather-icon icon="RefreshCwIcon"></feather-icon>
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'0.25x >>'"
                        v-b-tooltip.hover.v-primary
                        @click="speedUp(row)"
                        variant="outline-primary"
                      >
                        <feather-icon icon="ChevronsRightIcon"></feather-icon>
                      </b-button>
                      <b-button
                        @click="open_quality_form_modal(row)"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover.top="lang('t_qualityForm')"
                        v-b-tooltip.hover.v-primary
                        variant="outline-primary"
                      >
                        <feather-icon icon="CheckSquareIcon"></feather-icon>
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="lang('t_objectToForm')"
                        v-b-tooltip.hover.v-primary
                        @click="open_objection_modal(row)"
                        variant="outline-primary"
                      >
                        <feather-icon icon="AlertTriangleIcon"></feather-icon>
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="lang('t_openInNewWindow')"
                        v-b-tooltip.hover.v-primary
                        @click="openAudio(row.item)"
                        variant="outline-primary"
                      >
                        <feather-icon icon="ExternalLinkIcon"></feather-icon>
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
              </b-card>
            </template>

            <template #cell(EvaluateEvent)="data">
              <feather-icon
                :icon="data.value ? 'CheckIcon' : 'XIcon'"
                size="12"
                class="align-middle text-body"
              />
            </template>
            <template #cell(PunishEvent)="data">
              <feather-icon
                :icon="data.value ? 'CheckIcon' : 'XIcon'"
                size="12"
                class="align-middle text-body"
              />
            </template>
            <template #cell(RecordingFile)="data">
              {{ data.value.split("-").join("/").replace(/ /g, "\u00a0") }}
            </template>
            <template #cell(LineName)="data">
              {{
                data.item.Direction == "outbound"
                  ? data.item.Classification.split("-")
                      .join("/")
                      .replace(/ /g, "\u00a0")
                  : data.value.split("-").join("/").replace(/ /g, "\u00a0")
              }}
            </template>
            <template #cell(RecordingFileName)="data">
              <center>
                <b-button
                  class="py-25 px-75"
                  v-if="data.item.RecordingFile != ''"
                  size="sm"
                  @click="open_audio2(data)"
                  variant="outline-primary"
                >
                  <feather-icon
                    v-if="!data.detailsShowing"
                    icon="PlayIcon"
                  ></feather-icon>
                  <feather-icon v-else icon="XIcon"></feather-icon>
                </b-button>
                <!-- <b-button
                  v-if="data.item.RecordingFile != ''"
                  size="sm"
                  @click="openAudio(data.item)"
                  variant="outline-primary"
                >
                  <feather-icon icon="PlayIcon"></feather-icon>
                </b-button> -->
              </center>
            </template>
            <template #cell(RecordingFilePath)="data">
              <center>
                <b-button
                  v-if="data.item.RecordingFilePath != ''"
                  size="sm"
                  @click="openSecLineAudio(data.item)"
                  variant="outline-primary"
                >
                  <feather-icon icon="PlayIcon"></feather-icon>
                </b-button>
              </center>
            </template>
            <template #cell(Note)="data">
              <center>
                <b-button
                  class="py-25 px-75"
                  v-if="data.item.Note && data.item.Note.length > 0"
                  size="sm"
                  @click="openNote(data.item)"
                  variant="outline-primary"
                >
                  <feather-icon icon="InfoIcon"></feather-icon>
                </b-button>
              </center>
            </template>
            <template #cell(Queue)="data">
              {{
                queues.find((e) => e.internal_name == data.value)
                  ? queues
                      .find((e) => e.internal_name == data.value)
                      .display_name.replace(/ /g, "\u00a0")
                  : data.value
              }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row v-if="items.length > 0">
        <b-col cols="12">
          <b-pagination
            @input="getReportData()"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="mt-1"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      size="xl"
      v-model="quality_form_modal"
      @ok="setQualityResult"
      :ok-disabled="
        (selected_record && selected_record.item.EvaluateEvent) ||
        selected_form == null
      "
    >
      <b-row v-if="selected_record">
        <b-col cols="6">
          <v-select
            :disabled="selected_record && selected_record.item.EvaluateEvent"
            class="mt-75"
            v-model="selected_form"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="display_name"
            :reduce="(item) => item"
            :options="c_quality_forms"
            :placeholder="lang('t_qualityForm')"
            @input="calculate_point()"
          />
        </b-col>
      </b-row>

      <b-row class="mt-1" v-if="selected_form">
        <b-col>
          <b-tabs vertical pills card v-model="categoryTabIndex">
            <b-tab
              v-for="(category, i) in selected_form.categories"
              :key="i"
              :title="`${category.title} (${category.questions.reduce(
                (acc, curr) => acc + curr.calculated_point,
                0
              )})`"
            >
              <b-row>
                <b-col cols="8">
                  <b-tabs
                    nav-wrapper-class="nav-tabs"
                    v-model="category.questionTabIndex"
                  >
                    <b-tab
                      v-for="(question, j) in category.questions"
                      :key="question.question"
                    >
                      <template #title>
                        <span>{{
                          `${j + 1}.Soru (${question.calculated_point})`
                        }}</span>
                      </template>
                      <b-row>
                        <b-col>
                          <h4>
                            {{ question.question }}
                          </h4>
                        </b-col>
                      </b-row>
                      <b-row v-if="question.is_point_range">
                        <b-col>
                          <vue-slider
                            :min="Number(question.min_point)"
                            :max="Number(question.max_point)"
                            v-model="question.calculated_point"
                            :direction="
                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                            "
                            @change="calculate_point()"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col>
                          <b-row>
                            <b-col v-if="question.is_multiple">
                              <b-form-checkbox
                                v-model="answer.selected"
                                v-for="(answer, i) in question.answers"
                                :key="i"
                                :class="
                                  answer.punish
                                    ? 'custom-control-danger'
                                    : 'custom-control-primary'
                                "
                                @input="set_question_choices(question, answer)"
                              >
                                <span class="vs-checkbox">
                                  <span class="vs-checkbox--check">
                                    <!-- <i class="vs-icon feather icon-check" /> -->
                                    {{ `${answer.answer}` }}
                                  </span>
                                </span>
                              </b-form-checkbox>
                            </b-col>
                            <b-col v-else>
                              <b-form-radio
                                :class="
                                  answer.punish
                                    ? 'custom-control-danger'
                                    : 'custom-control-primary'
                                "
                                v-model="question.choice"
                                v-for="(answer, i) in question.answers"
                                :key="i"
                                :value="answer.answer"
                                @change="set_question_choice(question, answer)"
                              >
                                {{ answer.answer }}
                              </b-form-radio>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-textarea
                            class="mt-1"
                            rows="4"
                            v-model="question.description"
                            :placeholder="lang('t_description')"
                          >
                          </b-form-textarea>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <b-button-group size="sm">
                          <b-button @click="prev_question" variant="primary"
                            >Önceki</b-button
                          >
                          <b-button
                            @click="next_question"
                            class="ml-1"
                            variant="primary"
                            >Sonraki</b-button
                          >
                        </b-button-group>
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </b-col>
                <b-col cols="4">
                  <b-card no-body>
                    <b-card-text class="mx-2 my-1">
                      <b-row>
                        <b-col
                          class="my-25"
                          v-for="(field, i) in Object.keys(
                            selected_record.item
                          ).filter(
                            (e) => e != 'Note' && fields.find((j) => j.key == e)
                          )"
                          :key="i"
                          cols="6"
                        >
                          <b-badge
                            class="w-100 font-weight-bold"
                            variant="primary"
                          >
                            <span
                              v-if="field != 'Queue'"
                              style="width: 12rem"
                              class="d-inline-block text-truncate"
                            >
                              {{
                                `${fields.find((e) => e.key == field).label}: ${
                                  selected_record.item[field]
                                }`
                              }}
                            </span>
                            <span
                              v-else
                              style="width: 12rem"
                              class="d-inline-block text-truncate"
                            >
                              {{
                                queues.find(
                                  (e) =>
                                    e.internal_name ==
                                    selected_record.item[field]
                                )
                                  ? queues.find(
                                      (e) =>
                                        e.internal_name ==
                                        selected_record.item[field]
                                    ).display_name
                                  : selected_record.item[field]
                              }}
                            </span>
                          </b-badge>

                          <!-- {{ field }} -->
                        </b-col>
                      </b-row>
                    </b-card-text>
                    <b-card-footer>
                      <b-progress
                        :key="'success'"
                        animated
                        :value="calculated_point"
                        :variant="'success'"
                        :class="'progress-bar-' + 'success'"
                      />
                      <h4 class="text-center mt-1">{{ calculated_point }}</h4>
                    </b-card-footer>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
            <!-- <b-tab :title="lang('t_addNote')"> </b-tab> -->
            <template #tabs-end>
              <!-- <b-nav-item href="#" role="presentation" @click="() => {}"
                >Another tab</b-nav-item
              > -->
              <b-button
                v-b-modal.modal-note
                class="mt-auto"
                block
                variant="gradient-secondary"
              >
                {{ lang("t_addNote") }}
              </b-button>
            </template>
          </b-tabs>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      :title="lang('t_objections')"
      no-close-on-esc
      no-close-on-backdrop
      centered
      size="lg"
      v-model="modal_objection"
      hide-footer
    >
      <b-card v-if="selected_record">
        <b-card-text style="height: 40vh; overflow-y: auto">
          <b-row
            v-for="(message, i) in selected_record.item.ObjectionMessages"
            :key="i"
            class="px-1 mx-0"
          >
            <b-col>
              <span :class="message.owner != user ? 'msg_from' : 'msg_to'">
                {{ message.body }}
                <small class="time">
                  {{
                    new Date(message.created_at).toLocaleTimeString(
                      "tr-TR",
                      (options = {
                        hour: "numeric",
                        minute: "2-digit",
                      })
                    )
                  }}
                </small>
              </span>
            </b-col>
          </b-row>
        </b-card-text>

        <b-row>
          <b-col>
            <b-form-textarea v-model="objection_message"> </b-form-textarea>
            <b-button
              @click="setObjection"
              class="float-right mt-1"
              variant="primary"
              >{{ lang("t_send") }}</b-button
            >
            <b-button
              @click="removeEvaluation"
              class="float-right mt-1 mr-1"
              variant="danger"
              >{{ lang("t_removeEvaluation") }}</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      v-if="selected_form"
      static
      id="modal-note"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="lang('t_addNote')"
    >
      <b-form-group>
        <b-form-textarea
          rows="5"
          v-model="selected_form.note"
          id="note"
          :placeholder="lang('t_note')"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      static
      id="modal-filter"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="lang('t_filters')"
    >
      <b-form-group
        v-for="(filters, i) in Object.keys(filterOptions)"
        :key="i"
        :label="lang(filters)"
      >
        <b-form-checkbox
          v-for="(option, j) in Object.keys(filterOptions[filters])"
          :key="j"
          v-model="filterOptions[filters][option].value"
        >
          {{ lang(filterOptions[filters][option].display_name) }}
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
    <b-modal
      v-model="modal_activity_note"
      size="lg"
      static
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      :cancel-title="lang('t_cancel')"
      centered
      :title="lang('t_note')"
      @ok="setActivityLog"
    >
      <b-form-group :label="lang('t_note')">
        <b-form-textarea
          :placeholder="lang('t_note')"
          v-model="activity_note"
          rows="5"
        >
        </b-form-textarea>
      </b-form-group>
    </b-modal>
    <b-modal
      v-model="modal_download_activity_note"
      size="lg"
      static
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      :cancel-title="lang('t_cancel')"
      centered
      :title="lang('t_note')"
      @ok="setDownloadActivityLog"
    >
      <b-form-group :label="lang('t_note')">
        <b-form-textarea
          :placeholder="lang('t_note')"
          v-model="download_activity_note"
          rows="5"
        >
        </b-form-textarea>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BProgress,
  BImg,
  BButtonGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTable,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardText,
  BTabs,
  BTab,
  VBTooltip,
  BBadge,
  BFormCheckbox,
  BFormRadio,
  BCardFooter,
  BFormTextarea,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "animate.css";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import VueSlider from 'vue-slider-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { $themeColors } from "@themeConfig";

const startDate = new Date();
startDate.setHours(0, 0, 0, 0);
const endDate = new Date();
endDate.setHours(23, 55, 0, 0);

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      tmp_values: {
        "Condition": false,
        "StartTime": '',
        "RemoteNumber": '',
        "Agent": '',
        "Queue": '',
        "Verdict": '',
        "Direction": '',
        "TerminateSide": '',
        "WaitInQueueTime": '',
      },
      modal_activity_note: false,
      activity_note: '',
      modal_download_activity_note: false,
      download_activity_note: '',
      other_permission: [],
      wavesurfer: null,
      displaySound: false,
      currentTime: 0,
      modal_objection: false,
      is_progress: false,
      excel_url: "",
      full_screen: false,
      filter_values: {
        dateRanges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        selectedAgents: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedDirections: [],
        selectedCampaigns: [],
        selectedTerminateSides: [],
        selectedVerdicts: [],
        selectedInteractionID: '',
        selectedNumber: '',
        selectedMinWaitInQueue: '',
        selectedMaxWaitInQueue: '',
        selectedMinDuration: '',
        selectedMaxDuration: '',
        selectedIsEvaluated: [],
        selectedEvaluator: [],
        selectedEvaluatedStartTime: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        selectedEvaluatedEndTime: null,
        selectedMinQualityPoint: null,
        selectedMaxQualityPoint: null,
        selectedQualityForm: [],
        selectedIsObjection: [],
      },
      filterOptions: {
        "t_standart": {
          dateRanges: { value: true, internal_name: 'dateRanges', "display_name": "t_rDate", type: 'datetime' },
          selectedAgents: { value: true, internal_name: 'selectedAgents', "display_name": "t_rAgent", type: 'select', options: [], key: 'username' },
          selectedQueues: { value: true, internal_name: 'selectedQueues', "display_name": "t_rQueue", type: 'select', options: [], key: 'internal_name' },
          selectedProjects: { value: true, internal_name: 'selectedProjects', "display_name": "t_rProject", type: 'select', options: [], key: 'internal_name' },
          selectedDirections: { value: true, internal_name: 'selectedDirections', "display_name": "t_rDirection", type: 'select', options: [], key: 'internal_name' },
          selectedNumber: { value: true, internal_name: 'selectedNumber', "display_name": "t_rRemoteNumber", type: 'text' },
        },
        "t_advanced": {
          selectedCampaigns: { value: false, internal_name: 'selectedCampaigns', "display_name": "t_rCampaign", type: 'select', options: [], key: 'internal_name' },
          selectedTerminateSides: { value: false, internal_name: 'selectedTerminateSides', "display_name": "t_rTerminateSide", type: 'select', options: [], key: 'internal_name' },
          selectedVerdicts: { value: false, internal_name: 'selectedVerdicts', "display_name": "t_rVerdict", type: 'select', options: [], key: 'internal_name' },
          selectedInteractionID: { value: false, internal_name: 'selectedInteractionID', "display_name": "t_rInteractionID", type: 'text' },
          selectedMinWaitInQueue: { value: false, internal_name: 'selectedMinWaitInQueue', "display_name": "t_minWaitInQueue", type: 'number' },
          selectedMaxWaitInQueue: { value: false, internal_name: 'selectedMaxWaitInQueue', "display_name": "t_maxWaitInQueue", type: 'number' },
          selectedMinDuration: { value: false, internal_name: 'selectedMinDuration', "display_name": "t_minDuration", type: 'number' },
          selectedMaxDuration: { value: false, internal_name: 'selectedMaxDuration', "display_name": "t_maxDuration", type: 'number' },
        },
        "t_quality": {
          selectedIsEvaluated: { value: false, internal_name: 'selectedIsEvaluated', "display_name": "t_rIsEvaluated", type: 'select', options: [], key: 'internal_name' },
          selectedEvaluator: { value: false, internal_name: 'selectedEvaluator', "display_name": "t_rEvaluator", type: 'select', options: [], key: 'internal_name' },
          selectedEvaluatedStartTime: { value: false, internal_name: 'selectedEvaluatedStartTime', "display_name": "t_rEvaluatedStartTime", type: 'datetime' },
          selectedMinQualityPoint: { value: false, internal_name: 'selectedMinQualityPoint', "display_name": "t_rMinQualityPoint", type: 'number' },
          selectedMaxQualityPoint: { value: false, internal_name: 'selectedMaxQualityPoint', "display_name": "t_rMaxQualityPoint", type: 'number' },
          selectedQualityForm: { value: false, internal_name: 'selectedQualityForm', "display_name": "t_rQualityForm", type: 'select', options: [], key: 'internal_name' },
          selectedIsObjection: { value: false, internal_name: 'selectedIsObjection', "display_name": "t_rIsObjection", type: 'select', options: [], key: 'internal_name' },
        },
      },
      categoryTabIndex: 0,
      questionTabIndex: 0,
      audio_modal: false,
      quality_form_modal: false,
      selected_form: null,
      audio2: "",
      htmlcontent: "",
      selected_note: "",
      selected_audio: "",
      quality_forms: [],
      agents: [],
      projects: [],
      queues: [],
      campaigns: [],
      finish_codes: [],
      selected_date_filter_type: "today",
      selected_category: "",
      selected_report: "",
      selected_record: null,
      tmp_selected_record: null,
      is_punished: false,
      calculated_point: 0,
      date_filter_types: [
        {
          internal_name: "today",
          display_name: globalThis._lang("t_today"),
        },
        {
          internal_name: "yesterday",
          display_name: globalThis._lang("t_yesterday"),
        },
        {
          internal_name: "last_7_days",
          display_name: globalThis._lang("t_last7Days"),
        },
        {
          internal_name: "last_30_days",
          display_name: globalThis._lang("t_last30Days"),
        },
        {
          internal_name: "this_month",
          display_name: globalThis._lang("t_thisMonth"),
        },
        {
          internal_name: "last_month",
          display_name: globalThis._lang("t_lastMonth"),
        },
        {
          internal_name: "between_dates",
          display_name: globalThis._lang("t_betweenDates"),
        },
      ],
      perPage: 10,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      standart_filters: [
        {
          "internal_name": "Project",
          "display_name": "t_rProject"
        },
        {
          "internal_name": "Queue",
          "display_name": "t_rQueue"
        },
        {
          "internal_name": "Agent",
          "display_name": "t_rAgent"
        },
        {
          "internal_name": "RemoteNumber",
          "display_name": "t_rRemoteNumber"
        }
      ],
      advanced_filters: [
        {
          "internal_name": "TerminateSide",
          "display_name": "t_rTerminateSide"
        },
        {
          "internal_name": "Campaign",
          "display_name": "t_rCampaign"
        },
        {
          "internal_name": "Verdict",
          "display_name": "t_rVerdict"
        },
        {
          "internal_name": "InteractionID",
          "display_name": "t_rInteractionID"
        }
      ],

      quality_filters: [
        {
          "internal_name": "IsEvaluated",
          "display_name": "t_rIsEvaluated"
        },
        {
          "internal_name": "Evaluator",
          "display_name": "t_rEvaluator"
        },
        {
          "internal_name": "EvaluatedStartTime",
          "display_name": "t_rEvaluatedStartTime"
        },
        {
          "internal_name": "EvaluatedEndTime",
          "display_name": "t_rEvaluatedEndTime"
        },

        {
          "internal_name": "MinQualityPoint",
          "display_name": "t_rMinQualityPoint"
        },
        {
          "internal_name": "MaxQualityPoint",
          "display_name": "t_rMaxQualityPoint"
        },
        {
          "internal_name": "QualityForm",
          "display_name": "t_rQualityForm"
        },
        {
          "internal_name": "IsObjection",
          "display_name": "t_rIsObjection"
        },
      ],

      fields: [
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
        // {
        //   "key": "RecordingFileName",
        //   "label": '',
        //   "sortable": true
        // },
        {
          "key": "StartTime",
          "label": globalThis._lang("t_rStartTime").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        // {
        //   "key": "EndTime",
        //   "label": globalThis._lang("t_rEndTime").split("-").join("/").replace(/ /g, "\u00a0"),
        //   "sortable": true
        // },
        {
          "key": "RemoteNumber",
          "label": globalThis._lang("t_rRemoteNumber").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "Agent",
          "label": globalThis._lang("t_rAgent").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "Direction",
          "label": globalThis._lang("t_rDirection").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "Queue",
          "label": globalThis._lang("t_rQueue").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "Verdict",
          "label": globalThis._lang("t_rVerdict").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        // {
        //   "key": "ActionDate",
        //   "label": globalThis._lang("t_rActionDate").split("-").join("/").replace(/ /g, "\u00a0"),
        //   "sortable": "true"
        // },
        {
          "key": "Note",
          "label": globalThis._lang("t_rNote").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "ConnectedLength",
          "label": globalThis._lang("t_rConnectedLength").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        // {
        //   "key": "QueueWaitLength",
        //   "label": globalThis._lang("t_rQueueWaitLength").split("-").join("/").replace(/ /g, "\u00a0"),
        //   "sortable": "true"
        // },
        // {
        //   "key": "HoldLength",
        //   "label": globalThis._lang("t_rHoldLength").split("-").join("/").replace(/ /g, "\u00a0"),
        //   "sortable": true
        // },

        {
          "key": "TerminateSide",
          "label": globalThis._lang("t_rTerminateSide").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },

        // {
        //   "key": "LocalNumber",
        //   "label": globalThis._lang("t_rLocalNumber").split("-").join("/").replace(/ /g, "\u00a0"),
        //   "sortable": "true"
        // },
        {
          "key": "LineName",
          "label": globalThis._lang("t_rLineName").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": "true"
        },
        // {
        //   "key": "Classification",
        //   "label": globalThis._lang("t_rClassification").split("-").join("/").replace(/ /g, "\u00a0"),
        //   "sortable": "true"
        // },
        {
          "key": "TransferType",
          "label": globalThis._lang("t_rTransferType").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": "true"
        },
        {
          "key": "TransferTarget",
          "label": globalThis._lang("t_rTransferTarget").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": "true"
        },
        {
          "key": "Campaign",
          "label": globalThis._lang("t_rCampaign").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": "true"
        },
        {
          "key": "InteractionID",
          "label": globalThis._lang("t_rInteractionID").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        // {
        //   "key": "EvaluateEvent",
        //   "label": globalThis._lang("t_rEvaluateEvent").split("-").join("/").replace(/ /g, "\u00a0"),
        //   "sortable": true
        // },
        {
          "key": "Evaluator",
          "label": globalThis._lang("t_rEvaluator").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "EvaluateTime",
          "label": globalThis._lang("t_rEvaluateTime").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "QualityFormName",
          "label": globalThis._lang("t_rQualityFormName").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },

        {
          "key": "PunishEvent",
          "label": globalThis._lang("t_rPunishEvent").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "QualityPoint",
          "label": globalThis._lang("t_rQualityPoint").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        {
          "key": "LastObjectingUser",
          "label": globalThis._lang("t_rLastObjectingUser").split("-").join("/").replace(/ /g, "\u00a0"),
          "sortable": true
        },
        // {
        //   "key": "RecordingFile",
        //   "label": globalThis._lang("t_rRecordingFile").split("-").join("/").replace(/ /g, "\u00a0"),
        //   "sortable": true
        // },
      ],
      YesNoOptions: [
        { internal_name: true, display_name: globalThis._lang('t_yes') },
        { internal_name: false, display_name: globalThis._lang('t_no') }
      ],
      c_quality_forms: [],
      items: [],
      project_columns: [],
      report_permissions: [],
      column_labels: {
        status: "Durum",
        assigned_agent: "Agent",
        attempts: "Temas",
        total_attempts: "Toplam Temas",
        campaign: "Kampanya",
        finish_code: "Son Sonuç Kodu",
        insert_date: "Arama Tarihi",
        action_date: "Aranacak Tarih",
        action_time: "Aranacak Zaman",
        create_date: "Yüklenme Tarihi",
        create_time: "Yüklenme Zamanı",
        ex_agent: "Ex Agent",
        assign_date: "Atama Tarihi",
      },
      objection_message: '',
      user: '',
      statu_description: {
        C: "Aranabilir",
        E: "Manual Olarak Elendi",
        I: "Aranıyor",
        K: "Arama Kuralına Göre Kapatıldı",
        U: "Ulaşıldı",
      },
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    WaveSurfer,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BCardBody,
    DateRangePicker,
    BFormTextarea,
    BProgress,
    BBadge,
    BTabs,
    BTab,
    BImg,
    BCardActions,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTable,
    flatPickr,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    BCard,
    BCardText,
    vSelect,
    BButtonGroup,
    BFormCheckbox,
    BFormRadio,
    VueSlider,
    BCardFooter,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getSelectedFilters() {
      let arr = [];
      for (const item of Object.values(this.filterOptions)) {
        for (const filter of Object.values(item)) {
          if (filter.value) {
            arr.push(filter);
          }
        }
      }
      return arr;
    }
  },
  async mounted() {
    this.is_progress = true;
    this.other_permission = globalThis.permissions["other"] || [];

    this.user = globalThis.user.username;
    const this_data = JSON.parse(localStorage.getItem("records_cache"));
    if (![undefined, null].includes(this_data)) {
      for (let [key, val] of Object.entries(this_data)) {
        this[key] = val;
      }
    }

    this.wavesurfer = null;
    this.selected_record = null;


    this.full_screen = false;

    await this.get_users();
    await this.GetProjects();
    await this.GetQueues();
    await this.GetCampaigns();
    await this.GetFinishCodes();
    await this.get_quality_forms();

    this.filterOptions = {
      "t_standart": {
        dateRanges: { value: true, internal_name: 'dateRanges', "display_name": "t_rDate", type: 'datetime' },
        selectedAgents: { value: true, internal_name: 'selectedAgents', "display_name": "t_rAgent", type: 'select', options: this.agents, key: 'username', label: 'username' },
        selectedQueues: { value: true, internal_name: 'selectedQueues', "display_name": "t_rQueue", type: 'select', options: this.queues, key: 'internal_name', label: 'display_name' },
        selectedProjects: { value: true, internal_name: 'selectedProjects', "display_name": "t_rProject", type: 'select', options: this.projects, key: 'internal_name', label: 'display_name' },
        selectedDirections: {
          value: true, internal_name: 'selectedDirections', "display_name": "t_rDirection", type: 'select', options: [{ internal_name: 'outbound', display_name: 'outbound' }, { internal_name: 'inbound', display_name: 'inbound' }, { internal_name: 'dialer', display_name: 'dialer' }
          ], key: 'internal_name', label: 'display_name'
        },
        selectedNumber: { value: true, internal_name: 'selectedNumber', "display_name": "t_rRemoteNumber", type: 'text' },
      },
      "t_advanced": {
        selectedCampaigns: { value: false, internal_name: 'selectedCampaigns', "display_name": "t_rCampaign", type: 'select', options: this.campaigns, key: 'internal_name', label: 'display_name' },
        selectedTerminateSides: { value: false, internal_name: 'selectedTerminateSides', "display_name": "t_rTerminateSide", type: 'select', options: [{ internal_name: 'local', display_name: 'local' }, { internal_name: 'remote', display_name: 'remote' }], key: 'internal_name', label: 'display_name' },
        selectedVerdicts: { value: false, internal_name: 'selectedVerdicts', "display_name": "t_rVerdict", type: 'select', options: finish_codes, key: 'finish_code', label: 'finish_code' },
        selectedInteractionID: { value: false, internal_name: 'selectedInteractionID', "display_name": "t_rInteractionID", type: 'text' },
        selectedMinWaitInQueue: { value: false, internal_name: 'selectedMinWaitInQueue', "display_name": "t_minWaitInQueue", type: 'number' },
        selectedMaxWaitInQueue: { value: false, internal_name: 'selectedMaxWaitInQueue', "display_name": "t_maxWaitInQueue", type: 'number' },
        selectedMinDuration: { value: false, internal_name: 'selectedMinDuration', "display_name": "t_minDuration", type: 'number' },
        selectedMaxDuration: { value: false, internal_name: 'selectedMaxDuration', "display_name": "t_maxDuration", type: 'number' },
      },
      "t_quality": {
        selectedIsEvaluated: { value: false, internal_name: 'selectedIsEvaluated', "display_name": "t_rIsEvaluated", type: 'select', options: this.YesNoOptions, key: 'internal_name', label: 'display_name' },
        selectedEvaluator: { value: false, internal_name: 'selectedEvaluator', "display_name": "t_rEvaluator", type: 'select', options: this.agents, key: 'username', label: 'username' },
        selectedEvaluatedStartTime: { value: false, internal_name: 'selectedEvaluatedStartTime', "display_name": "t_rEvaluatedStartTime", type: 'datetime' },
        selectedMinQualityPoint: { value: false, internal_name: 'selectedMinQualityPoint', "display_name": "t_rMinQualityPoint", type: 'number' },
        selectedMaxQualityPoint: { value: false, internal_name: 'selectedMaxQualityPoint', "display_name": "t_rMaxQualityPoint", type: 'number' },
        selectedQualityForm: { value: false, internal_name: 'selectedQualityForm', "display_name": "t_rQualityForm", type: 'select', options: this.quality_forms, key: '_id', label: 'display_name' },
        selectedIsObjection: { value: false, internal_name: 'selectedIsObjection', "display_name": "t_rIsObjection", type: 'select', options: this.YesNoOptions, key: 'internal_name', label: 'display_name' },
      },
    },

      this.is_progress = false;
    // this.GetInboundRouteTargets();
  },
  beforeDestroy() {
    localStorage.setItem("records_cache", JSON.stringify(this.$data));
  },
  methods: {
    async OpenApplyDeleteForm(item) {
      this.selected_record = JSON.parse(JSON.stringify(item));
      await this.removeEvaluation();
    },
    async downloadAudio(item) {
      try {

        let audioUrl = item.RecordingFile;
        let _url = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${audioUrl}`;
        let _name_parts = audioUrl.split('/');
        const response = await fetch(_url);
        if (!response.ok) throw new Error('Ses yüklenirken bir hata oluştu.');
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = _name_parts[_name_parts.length - 1].replace('.ogg', '.wav');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Dosya indirilirken hata oluştu: ', error);
      }
    },
    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

      return minutes + ":" + remainingSeconds;
    },
    setSoundSpeed(speed) {
      if (this.wavesurfer) {
        this.wavesurfer.setPlaybackRate(speed, true);
        this.wavesurfer.play();
      }
    },
    async setActivityLog() {
      const response = (
        await this.$http_in.post(`/spv/v1/SetQualityLog`, {
          InteractionID: this.tmp_selected_record.InteractionID,
          Event: 'Play Record',
          Note: this.activity_note,
        })
      ).data;

      this.loadSound(this.tmp_selected_record);
    },
    async setDownloadActivityLog() {
      const response = (
        await this.$http_in.post(`/spv/v1/SetQualityLog`, {
          InteractionID: this.tmp_selected_record.InteractionID,
          Event: 'Download Record',
          Note: this.download_activity_note,
        })
      ).data;

      this.downloadAudio(this.tmp_selected_record);

    },
    checkActivityLog(item) {
      this.tmp_selected_record = item;

      if (globalThis.v_navbar.other_permission.includes("records_activity_log")) {
        this.activity_note = '';
        this.modal_activity_note = true;
      } else {
        this.loadSound(item);
      }
    },
    checkDownloadActivityLog(item) {
      this.tmp_selected_record = item;

      if (globalThis.v_navbar.other_permission.includes("records_activity_log")) {
        this.download_activity_note = '';
        this.modal_download_activity_note = true;
      } else {
        this.downloadAudio(item);
      }
    },
    loadSound(item) {
      if (item.RecordingFile) {
        let url = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;
        console.log(item);
        var myDiv = document.getElementById(`waveform`);
        myDiv.innerHTML = null;
        this.wavesurfer = null;
        this.currentTime = 0;
        this.wavesurfer = globalThis.WaveSurfer.create({
          height: 60,

          container: `#waveform`,
          waveColor: '#F0F0F1',
          progressColor: $themeColors.primary,
          // url: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav',
          url: url,
          audioRate: 1,
          // splitChannels: [
          //   {
          //     waveColor: 'rgb(200, 0, 200)',
          //     progressColor: 'rgb(100, 0, 100)',
          //   },
          //   {
          //     waveColor: 'rgb(0, 200, 200)',
          //     progressColor: 'rgb(0, 100, 100)',
          //   },
          // ],
          // Set a bar width

          barWidth: 4,
          // Optionally, specify the spacing between bars
          barGap: 3,
          // And the bar radius
          barRadius: 8,
        })
        console.log("wavesurfer", this.wavesurfer)

        this.wavesurfer.once('interaction', () => {
          console.log("interaction")
          this.wavesurfer.play()
        })
        this.wavesurfer.on('audioprocess', (currentTime) => this.currentTime = currentTime);

        this.displaySound = true;
      }
    },
    closeSound() {
      this.tmp_selected_record = null;
      this.displaySound = false;
      var myDiv = document.getElementById(`waveform`);
      myDiv.innerHTML = null;
      this.wavesurfer = null;
      this.currentTime = 0;
    },

    setDateFormat(date_string) {
      const date_object = new Date(date_string);

      const day = String(date_object.getDate()).padStart(2, '0');
      const month = String(date_object.getMonth() + 1).padStart(2, '0');
      const year = date_object.getFullYear();
      const hour = String(date_object.getHours()).padStart(2, '0');
      const minute = String(date_object.getMinutes()).padStart(2, '0');

      const formatted_date = `${day}/${month}/${year} ${hour}:${minute}`;
      return formatted_date;
    },
    async removeEvaluation() {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = (
            await this.$http_in.delete(`/spv/v1/Evaluation/${this.selected_record.item.InteractionID}`)
          ).data;
          await this.getReportData();


          if (response && response.acknowledged) {
            this.$swal({
              title: globalThis._lang("t_processSuccess"),
              text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: globalThis._lang("t_error"),
              text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
    rowClassRecords(item, type) {
      console.log("item", item);
      console.log("this.tmp_selected_record && this.tmp_selected_record._id == item._id", this.tmp_selected_record && this.tmp_selected_record._id == item._id);
      if (this.tmp_selected_record && this.tmp_selected_record._id == item._id) {
        return "table-primary table-style text-dark font-weight-bolder"
      }
      if (!item || type !== 'row') return
      if (item.LastObjectingUser == null) return "";
      if (item.AcceptObjectionEvent == true) return "table-success table-style";
      if (item.LastObjectingUser == this.user) return "table-info table-style";
      if (item.LastObjectingUser != this.user) return "table-warning table-style";

    },
    async setObjection() {
      if (this.objection_message.trim().length == 0) {
        this.$swal({
          title: globalThis._lang('t_warning'),
          text: globalThis._lang('t_pleaseDoNotLeaveAnyBlankFields'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return;
      }


      let objection_info = {
        owner: globalThis.user.username,
        body: this.objection_message.trim(),
        created_at: new Date().getTime()
      };

      const response = (
        await this.$http_in.post(`/agent/v1/Objection`, {
          InteractionID: this.selected_record.item.InteractionID,
          Objection: objection_info,
        })
      ).data;

      if (response && response.acknowledged) {
        this.selected_record.item.ObjectionMessages.push(objection_info);
        this.objection_message = '';
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }


    },
    open_objection_modal(item) {
      this.selected_record = JSON.parse(JSON.stringify(item));
      this.modal_objection = true;
      console.log("selected_record", selected_record)
    },
    getQualityForms() {
      let tmp_qf = [];
      for (const item of [...this.quality_forms]) {
        console.log("item", item);
        console.log("this.selected_record.item", this.selected_record.item);

        if (item.directions.length == 0 && item.queues.length == 0 && item.verdicts.length == 0) {
          tmp_qf.push(item);
        }

        else if (item.directions.length > 0 && item.directions.includes(this.selected_record.item.Direction)) {
          tmp_qf.push(item);
        }
        else if (item.queues.length > 0 && item.queues.includes(this.selected_record.item.Queue)) {
          tmp_qf.push(item);
        }
        else if (item.verdicts.length > 0 && item.verdicts.includes(this.selected_record.item.Verdict)) {
          tmp_qf.push(item);
        }
      }


      // let tmp_qf2 = [...this.quality_forms]
      //   .filter(e => e.directions.length > 0 && e.directions.includes(this.selected_record.item.Direction))
      // // .filter(e => e.queues.length > 0 && e.queues.includes(this.selected_record.item.Queue))
      // // .filter(e => e.verdicts.length > 0 && e.verdicts.includes(this.selected_record.item.Verdict))

      // console.log("getQualityForms2", tmp_qf2);

      this.c_quality_forms = Array.from(new Set(tmp_qf));

    },
    setQualityResult: async function (bvModalEvent) {
      this.is_progress = true;

      var exitLoop = false;
      for (let category of this.selected_form.categories) {
        for (let question of category.questions) {
          console.log("question", question);
          // console.log("is_point_range", question.is_point_range);
          // console.log("q2", (question.multiple && question.choices.length == 0));
          // console.log("q3", (!question.multiple && question.choice == ''));
          console.log("question.multiple", question.is_multiple)
          let condition_control = true;
          if (question.is_multiple) {
            if (question.choices && question.choices.length == 0) {
              console.log("test1")
              condition_control = false;
            }
          } else {
            if (question.choice == '') {
              console.log("test2")
              condition_control = false;
            }
          }
          console.log("condition_control", condition_control);

          if (question.is_point_range == false && !condition_control) {
            exitLoop = true;
            this.$swal({
              title: globalThis._lang("t_warning"),
              text: `Category: ${category.title} - Question: ${question.question}`,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            break;
          }
        }

        if (exitLoop) {
          break;
        }
      }
      if (exitLoop) {
        bvModalEvent.preventDefault();
        this.is_progress = false;
        this.selected_record = null;
        this.selected_form = null;
        this.is_progress = false;
        this.categoryTabIndex = 0;
        this.getReportData();
        return;
      }

      const response = (
        await this.$http_in.post(`/spv/v1/QualityResult`, {
          Data: {
            EvaluateEvent: true,
            PunishEvent: this.is_punished,
            QualityPoint: this.calculated_point,
            QualityNote: this.selected_form.note,
            Evaluator: globalThis.user.username,
            QualityFormID: this.selected_form._id,
            EvaluatedQualityForm: this.selected_form,
            Agent: this.selected_record.item.Agent,
            ObjectionEvent: false,
            ObjectionMessages: [],
          },
          InteractionID: this.selected_record.item.InteractionID

        })
      ).data;
      this.selected_record = null;
      this.selected_form = null;
      this.is_progress = false;
      this.questionTabIndex = 0;
      await this.getReportData();
      await this.get_quality_forms();
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    calculate_point() {
      this.is_punished = false;
      this.calculated_point = 0;
      let reset_form = false;
      for (let category of this.selected_form.categories) {
        let _category_point = 0;
        for (let question of category.questions) {
          if (question.punish.includes('question')) {
            // question.calculated_point = 0;
            this.is_punished = true;
            continue;
          }
          _category_point += Number(question.calculated_point);
          if (question.punish.includes('category')) {
            this.is_punished = true;
            _category_point = 0;
            break;
          }
          if (question.punish.includes('form')) {
            this.is_punished = true;
            reset_form = true;
            break;
          }
        }

        this.calculated_point += _category_point;
      }
      if (reset_form) {
        this.calculated_point = 0;
      }

    },
    set_question_choices(question, answer) {

      if (!question.choices) {
        question.choices = [];
      }
      if (!question.calculated_point) {
        question.calculated_point = 0;
      }

      if (!question.choices.includes(answer)) {
        question.choices.push(answer);
        question.calculated_point += Number(answer.point);
        if (answer.punish) {
          question.punish.push(answer.punish);
        }

      } else {
        question.choices = question.choices.filter(e => e != answer);
        question.calculated_point -= Number(answer.point);
        if (answer.punish) {
          question.punish.shift(answer.punish);
        }
      }
      this.calculate_point();
      console.log("this.selected_form", this.selected_form);
    },
    set_question_choice(question, answer) {

      question.calculated_point = Number(answer.point);
      if (answer.punish) {
        question.punish = [];
        question.punish.push(answer.punish);
      }
      this.calculate_point();
    },
    prev_question() {

      let selected_category = this.selected_form.categories[this.categoryTabIndex];
      console.log("selected_category", selected_category);

      if (this.selected_form.categories[this.categoryTabIndex].questionTabIndex == 0) {
        console.log("Geldi", this.categoryTabIndex);
        this.categoryTabIndex--;
        selected_category = this.selected_form.categories[this.categoryTabIndex];
        this.selected_form.categories[this.categoryTabIndex].questionTabIndex = selected_category.questions.length - 1;

      } else {
        this.selected_form.categories[this.categoryTabIndex].questionTabIndex--;

      }

      // this.selected_form.categories[this.categoryTabIndex].questionTabIndex--;
    },
    next_question() {
      let selected_category = this.selected_form.categories[this.categoryTabIndex];
      console.log("selected_category", selected_category);

      if (this.selected_form.categories[this.categoryTabIndex].questionTabIndex + 1 == selected_category.questions.length) {
        console.log("Geldi", this.categoryTabIndex);
        this.categoryTabIndex++;
        this.selected_form.categories[this.categoryTabIndex].questionTabIndex = 0;

      } else {
        this.selected_form.categories[this.categoryTabIndex].questionTabIndex++;

      }

    },
    open_quality_form_modal(item) {
      this.quality_form_modal = true;
      console.log("data", item);
      this.selected_record = JSON.parse(JSON.stringify(item));
      this.selected_form = item.item.EvaluatedQualityForm ? item.item.EvaluatedQualityForm : null;
      this.calculated_point = item.item.QualityPoint;
      this.categoryTabIndex = 0;
      this.questionTabIndex = 0;
      this.getQualityForms();
    },
    open_audio2(item) {
      item.toggleDetails();
      item.item.play_back_rate = 1;
      item.item.show_quality_form = false;
      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.item.RecordingFile}`;

    },
    speedUp(item) {
      var a = document.getElementById(`voyce_audio_${new Date(item.item.StartTime).getTime()}`);
      var span = document.getElementById("a_rate");
      a.playbackRate += 0.25;
      item.item.play_back_rate = a.playbackRate;
      item.item = { ...item.item }
      // span.innerHTML = a.playbackRate;
    },
    speedNormal(item) {
      var a = document.getElementById(`voyce_audio_${new Date(item.item.StartTime).getTime()}`);
      var span = document.getElementById("a_rate");
      a.playbackRate = 1.0;
      item.item.play_back_rate = a.playbackRate;
      item.item = { ...item.item }

      // span.innerHTML = a.playbackRate;
    },

    speedDown(item) {
      var a = document.getElementById(`voyce_audio_${new Date(item.item.StartTime).getTime()}`);
      var span = document.getElementById("a_rate");
      a.playbackRate -= 0.25;
      item.item.play_back_rate = a.playbackRate;
      item.item = { ...item.item }

      // span.innerHTML = a.playbackRate;
    },
    formatName(value) {
      return value || "-";
    },
    clear_report() {
      this.items = [];
      this.filter_values = {
        ...this.filter_values,
        // startDate: new Date(new Date((new Date().setHours(0, 0, 0, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        // endDate: new Date(new Date((new Date().setHours(23, 59, 59, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        dateRanges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        selectedAgents: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedCampaigns: [],
        selectedCustomerColumns: [],
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        selectedSL: 20,
        isFileNotNull: false,
      };

      this.selected_report = "";
    },
    clear_data() {
      this.items = [];
      // this.selected_date_filter_type == 'today';
      this.filter_values = {
        ...this.filter_values,
        // startDate: new Date(new Date((new Date().setHours(0, 0, 0, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        // endDate: new Date(new Date((new Date().setHours(23, 59, 59, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        dateRanges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        selectedAgents: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedCampaigns: [],
        selectedCustomerColumns: [],
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        selectedSL: 20,
        isFileNotNull: false,
      };
    },

    getFilterOptions() {
      if (this.selected_category == "project") {
        return this.projects;
      }
      if (this.selected_category == "queue") {
        return this.queues;
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getReportData: async function (_current_page = null) {
      try {
        this.is_progress = true;
        if (_current_page) {
          this.currentPage = 1;
        }

        const response = (
          await this.$http_in.post(`/spv/v1/QualityRecords`, {
            page: this.currentPage,
            limit: this.perPage,
            filters: {
              ...this.filter_values,
              selectedQueues: this.filter_values.selectedQueues.length == 0 ? this.queues.map(e => e.internal_name) : this.filter_values.selectedQueues
            },
          })
        ).data;



        for (const item of response.data) {
          for (let key of Object.keys(item)) {
            if (["RecordingFile", "RecordingFilePath"].includes(key)) {
              continue;
            }
            item[key] =
              typeof item[key] == "string"
                ? item[key].split("-").join(".").replace(/ /g, "\u00a0")
                : item[key];
          }
        }
        this.items = response.data;
        this.totalRows = response.totalItems;

      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.is_progress = false;
      }

      // this.dialog = false;
      // this.is_progress = false;
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`report/v1/User`)).data;

      this.agents = response.filter(e => e.type != 'extension');
    },
    get_quality_forms: async function () {
      var response = (
        await this.$http_in.get(
          `/spv/v1/QualityForm/1/100`
        )
      ).data;
      for (let item of response.result) {
        console.log(item);
        item.note = '';
        for (let category of item.categories) {
          for (let question of category.questions) {
            question["calculated_point"] = 0;
            question["description"] = '';
            question["choice"] = '';
            question["choices"] = [];
            question["punish"] = [];
            for (const answer of question.answers) {
              if (answer.selected) {
                question.choices.push(answer);
                question.calculated_point += Number(answer.point);
                if (answer.punish) {
                  question.punish.push(answer.punish);
                }
              }

            }
          }
        }
      }

      this.quality_forms = response.result;

      console.log(this.quality_forms);
    },

    secondsToHHMMSS(seconds) {
      return (
        Math.floor(seconds / 3600) +
        ":" +
        ("0" + (Math.floor(seconds / 60) % 60)).slice(-2) +
        ":" +
        ("0" + (seconds % 60)).slice(-2)
      );
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`report/v1/Project`)).data;

      this.projects = response;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      // this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
        }
      }
    },
    GetFinishCodes: async function () {
      var response = (await this.$http_in.get(`spv/v1/FinishCodes`)).data;

      this.finish_codes = response;
    },
    GetCampaigns: async function () {
      var response = (await this.$http_in.get(`report/v1/Campaign`)).data;

      this.campaigns = response;
    },
    getCustomerColumns: async function () {
      // //console.log('this.agent.Project.INTERNAL_NAME', this.agent);
      var response = (await this.$http_in.get(`crm/v1/CustomerColumns`)).data;
      this.project_columns = [];
      this.columns = response[0].allkeys.sort();
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "notes",
            "phones",
            "products",
            "extra_data",
            "sms",
            "attempts",
            "campaign",
          ].includes(item)
        ) {
          continue;
        }
        this.project_columns.push({
          text:
            this.column_labels[item] == undefined
              ? item
              : this.column_labels[item],
          value: item,
        });
      }
    },
    GetLine: async function () {
      var response = (await this.$http_in.get(`report/v1/Line`)).data;

      this.lines = response;
    },
    GetReports: async function () {
      var response = (await this.$http_in.get(`report/v1/Reports`)).data;

      this.report_list = [];
      for (const item of response) {
        if (this.report_permissions.includes(item.internal_name)) {
          item.display_name = globalThis._lang(item.display_name);
          this.report_list.push(item);
        }
      }
      console.log("report_list", this.report_list);
    },
    close_modal() {
      this.audio2 = document.getElementById("voyce_audio");
      this.audio2.pause();
      this.selected_audio = "";
    },
    openAudio(item) {
      // console.log(item);
      const _self = this;
      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }
      // this._self.Condition = true;
      // const element = this.$refs.voyceCard;
      // html2canvas(element).then(canvas => {

      // const imgData = canvas.toDataURL();
      // _self.tmp_values.Condition = false;
      this.htmlcontent = `<!DOCTYPE html>
    <html>
        <head>
         <meta charset="utf-8" />
            <title>${item.Verdict} - ${item.BaseRemoteNumber}</title>
        </head>
        <style>
         body {
            font-family: sans-serif,Arial;
          }

           .btn {
           
            align-items: center;
            padding: 6px 14px;
            font-family: sans-serif,-apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
            border-radius: 6px;
            border: none;

            color: #fff;
            background: linear-gradient(180deg, #7367F0 0%, #7367F0 100%);
            background-origin: border-box;
            box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
          }

          .btn:focus {
            box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
            outline: 0;
          }
          
        </style>
        <body>

        <span style="color:#7367F0;font-weight:bold" >[Tarih] => </span><span>${item.StartTime}</span>
        <br>
        <br>
        <span style="color:#7367F0;font-weight:bold">[Telefon] =>  </span><span>${item.BaseRemoteNumber}</span>
        <br>
        <br>
        <span style="color:#7367F0;font-weight:bold">[Sonuç Kodu] => </span><span>${item.Verdict}</span>
        <br>
        <br>
        <span style="color:#7367F0;font-weight:bold">[Temsilci] => </span><span>${item.Agent}</span>
        <br>
        <br>
        <span style="color:#7367F0;font-weight:bold">[Kuyruk] => </span><span>${this.queues.find((e) => e.internal_name == item.Queue)
          ? this.queues.find((e) => e.internal_name == item.Queue)
            .display_name
          : item.Queue
        }</span>
        <br>
        <br>
           <audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("download_sound")
          ? ""
          : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
              <source src="${this.selected_audio}" type="audio/mpeg">
              Your browser does not support the audio element.
           </audio>
      ${globalThis.script}
           
        </body>
    </html>
      `;
      const winUrl = URL.createObjectURL(
        new Blob([this.htmlcontent], { type: "text/html" })
      );
      window.open(
        winUrl,
        "_blank",
        `width=800,height=400,screenX=200,screenY=200`
      );

      // });


      return;
      // window.open(this.selected_audio, item.BaseRemoteNumber, "_blank");

      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.Verdict}</span><br><span class="font-weight-bolder">  ${item.BaseRemoteNumber}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },
    openSecLineAudio(item) {
      // console.log(item);

      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/secondline/${item.RecordingFilePath}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }

      this.htmlcontent = `<!DOCTYPE html>
    <html>
        <head>
         <meta charset="utf-8" />
            <title>${item.RemoteNumber}</title>
        </head>
        <body>
        <h4>[Tarih] => ${item.StartTime}</h4>
        <h4>[Telefon] =>  ${item.RemoteNumber}</h4>
        <h4>[Dahili] => ${item.LocalNumber}</h4>
           <audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("download_sound")
          ? ""
          : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
              <source src="${this.selected_audio}" type="audio/mpeg">
              Your browser does not support the audio element.
           </audio>
      ${globalThis.script}
           
        </body>
    </html>
      `;
      const winUrl = URL.createObjectURL(
        new Blob([this.htmlcontent], { type: "text/html" })
      );
      window.open(
        winUrl,
        "_blank",
        `width=800,height=400,screenX=200,screenY=200`
      );
      return;
      // window.open(this.selected_audio, item.BaseRemoteNumber, "_blank");

      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.Verdict}</span><br><span class="font-weight-bolder">  ${item.BaseRemoteNumber}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },
    openNote(item) {
      this.$swal({
        title: item,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      });
    },
    async onexport() {
      // if (this.excel_url != '') {
      //   // window.location.assign(this.excel_url);
      //   this.is_progress = true;
      //   try {
      //     var response = await this.$http_ex.get(this.excel_url);
      //     console.log("response.status", response.status);
      //     if (response.status == 200) {
      //       var anchor = document.createElement('a');
      //       anchor.href = this.excel_url;
      //       anchor.download = this.excel_url;
      //       anchor.target = '_blank';
      //       document.body.appendChild(anchor);
      //       anchor.click();
      //       this.is_progress = false;
      //       return;
      //     }
      //     else {
      //       this.onexport();
      //     }
      //   } catch (error) {
      //     this.onexport();
      //   }

      //   // await new Promise(res => setTimeout(res, 10000));

      // }
      // return;
      // On Click Excel download button
      let _headers = {};
      for (const header of this.fields) {
        _headers[header.key] = header.label;
      }
      console.log("_headers", _headers);
      console.log("this.fields", this.fields);

      let _data = [];
      for (const row of this.items) {
        let obj = {};

        for (const column of Object.keys(_headers)) {
          let key = _headers[column] == undefined ? column : _headers[column];
          obj[key] = row[column];
        }
        _data.push(obj);
      }

      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(
        wb,
        data,
        `${this.selected_report.display_name.split("/").join("-")}`
      ); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(
        wb,
        `${this.selected_report.display_name.split("/").join("-")}_${this.filter_values.dateRanges
        }.xlsx`
      ); // name of the file is 'book.xlsx'
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-slider.scss";

.nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  max-width: 100%;
  overflow: auto;
}

.daterangepicker {
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;
}
.daterangepicker td.in-range {
  background-color: #bfb9fa;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.reportrange-text {
  padding: 8px 10px !important;
}

.daterangepicker td.active:hover {
  background-color: #7367f0;
  border-color: transparent;
  color: #fff;
}

.daterangepicker .ranges li.active {
  background-color: #7367f0;
  color: #fff;
}

.daterangepicker select.hourselect {
  text-align: center;
}
.daterangepicker select.minuteselect {
  text-align: center;
}
.form-control {
  -webkit-appearance: auto !important;
}
</style>
<style scoped>
/* @import "@core/scss/vue/libs/vue-flatpicker.scss"; */
#waveform {
  cursor: pointer;
  height: 100%;
  /* position: relative; */
}
#hover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  width: 0;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}
#waveform:hover #hover {
  opacity: 1;
}
#time,
#duration {
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  font-size: 11px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px;
  color: #ddd;
}
#time {
  left: 0;
}
#duration {
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}
</style>
